.container {
  display: flex;
  flex-direction: column;
  width: 250px;
}
.menuWrapper {
  position: absolute;
  top: 4px;
  right: 4px;
}
.content {
  display: flex;
  flex-direction: column;
}
.avatar {
  margin: 8px;
  width: 64;
  height: 64px;
  align-self: center;
}
.info {
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.divider {
  margin: 8px;
}
.signOut {
  margin-right: 8px;
  margin-bottom: 8px;
}
.name {
  font-weight: 550;
  font-size: 14;
}
.email {
  font-size: 13;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}