.toolbar {
	align-items: flex-start;
	background-color: #616161;
}
.title {
	text-align: start;
	flex-grow: 1;
  color: #00E676;
}
.menu_button {
	color: #00E676 !important;
}
.link {
  text-decoration: none;
}
.image {
  width: 70%;
}